import React, { useEffect, useRef } from "react";
import stampImage from "../image/BfiStamp.png";
import { Button, Toolbar } from "@mui/material";
import { useParams } from "react-router-dom";
import moment from "moment";
import axiosWithToken from "../../utils/components/axiosTokenConfig";
import { BASE_URL } from "../../global";

const Sticker = ({ selectedFormID }) => {
  const elementRef = useRef(null);
  const { reportID } = useParams();
  const [newReportID, setNewReportID] = React.useState(
    reportID || selectedFormID
  );
  const [inputStates, setInputStates] = React.useState({});
  const [loading, setLoading] = React.useState(false);

  const handlePrint = () => {
    const printContent = document.getElementById("formToPrint");
    const originalContents = document.body.innerHTML;

    if (printContent) {
      document.body.innerHTML = elementRef.current.innerHTML;
      window.print();
      document.body.innerHTML = originalContents;
      window.location.reload();
    }
  };
  const fetchformReport = async (id) => {
    setLoading(true);
    try {
      const res = await axiosWithToken.get(`${BASE_URL}reportValues/${id}`);
      const data = res.data[0];

      if (!data || !data.templateValues) {
        console.log("Template data not found or invalid");
        return;
      }

      const dataObject = data.templateValues && JSON.parse(data.templateValues);

      const newDataObject = { ...dataObject };
      // delete newDataObject.reportDate;
      setInputStates(newDataObject);
    } catch (error) {
      console.error("Failed to fetch template: ", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (newReportID > 0) {
      fetchformReport(newReportID);
    }
  }, [newReportID]);

  return (
   
      <div
        style={{
          pageBreakInside: "avoid !important",
          height: "100%",
          marginTop: "10px",
        }}
      >
        <table
          style={{
            height: "100%",
            marginTop: "10px",
            pageBreakInside: "avoid",
          }}
        >
          <tbody id="singlebordertable">
            <tr>
              {/* <td style={{ width: "140px", padding: "2px" }}>
                  <img
                    style={{ height: "120px", width: "100%" }}
                    src={stampImage}
                  />{" "}
                </td> */}
              <td
                colSpan={4}
                style={{
                  textAlign: "center",
                  padding: "5px",
                  borderBottom: "none",
                }}
              >
                <h4
                  style={{
                    fontSize: "1.2rem",
                  }}
                >
                  TESTING/EXAMINATION DETAILS
                </h4>

                {/* <h6>
                      Kalyan: 113, Blossom Commercial Bldg., Raheja
                      Complex,Kalyan( West ), Dist. Thane. Maharashtra 421301
                      8652233801/ 03 & 9821189331
                    </h6>
                    <hr />
                    <h6>
                      Nashik: 02, Shri Sai Apartment, Pakhal Road, Dwarka,
                      Nashik 422011 8652233800/02/05
                    </h6> */}
              </td>
            </tr>
            <tr>
              <td
                colSpan={4}
                style={{
                  textAlign: "center",
                  padding: "2px",
                  fontSize: "10px",
                  borderBottom: "none",
                }}
              >
                REPORT NO. <b>{inputStates["reportNumber"] || "\u00A0"}</b>
              </td>
            </tr>
            <tr>
              <td
                colSpan={4}
                style={{
                  textAlign: "center",
                  padding: "2px",
                }}
              >
                <span style={{ fontSize: "10px" }}>
                  {inputStates["distinctiveNumber"] || "\u00A0"}
                </span>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  fontWeight: "bold",
                  textAlign: "center",
                  fontSize: "10px",
                  padding: "2px",
                }}
              >
                Description{" "}
              </td>
              <td
                style={{
                  fontWeight: "bold",
                  textAlign: "center",
                  fontSize: "10px",
                  padding: "2px",
                }}
              >
                {" "}
                Ultrasonic{" "}
              </td>
              <td
                style={{
                  fontWeight: "bold",
                  textAlign: "center",
                  fontSize: "10px",
                  padding: "2px",
                }}
              >
                Internal
              </td>
              <td
                style={{
                  fontWeight: "bold",
                  textAlign: "center",
                  fontSize: "10px",
                  padding: "2px",
                }}
              >
                Hydraulic
              </td>
            </tr>
            <tr>
              <td
                style={{
                  fontWeight: "bold",
                  textAlign: "center",
                  fontSize: "10px",
                  padding: "2px",
                }}
              >
                Date of Testing{" "}
              </td>
              <td
                style={{
                  fontWeight: "bold",
                  textAlign: "center",
                  fontSize: "10px",
                  padding: "2px",
                }}
              >
                {moment(inputStates["reportDate"]).format("DD/MM/YYYY")}
              </td>
              <td
                style={{
                  fontWeight: "bold",
                  textAlign: "center",
                  fontSize: "10px",
                  padding: "2px",
                }}
              ></td>
              <td
                style={{
                  fontWeight: "bold",
                  textAlign: "center",
                  fontSize: "10px",
                  padding: "2px",
                }}
              ></td>
            </tr>
            <tr>
              <td
                style={{
                  fontWeight: "bold",
                  textAlign: "center",
                  fontSize: "10px",
                  padding: "2px",
                }}
              >
                Next Due Date{" "}
              </td>
              <td
                style={{
                  fontWeight: "bold",
                  textAlign: "center",
                  fontSize: "10px",
                  padding: "2px",
                }}
              ></td>
              <td
                style={{
                  fontWeight: "bold",
                  textAlign: "center",
                  fontSize: "10px",
                  padding: "2px",
                }}
              ></td>
              <td
                style={{
                  fontWeight: "bold",
                  textAlign: "center",
                  fontSize: "10px",
                  padding: "2px",
                }}
              ></td>
            </tr>
          </tbody>
        </table>
      </div>
  );
};

export default Sticker;
